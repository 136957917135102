import { z } from 'zod'
import { NumberSetSchema } from '#app/utils/schema.ts'

export enum ItineraryVisibility {
  Private = 'private',
  Public = 'public',
}

export const WithMongoIdSchema = z.object({ _id: z.string() })

/**
 * Metadata Schema
 */

export const ItineraryMetadataSchema = z.object({
  destination: z
    .object({
      primaryId: z.number().nullish(),
      ids: NumberSetSchema.nullish(),
    })
    .nullish(),
  // TODO (andrew): eventually this should be pregenerated as an object w/
  //                multiple sizes and cached in a CDN
  thumbnail: z.array(z.string()).nullish(),
})
export type ItineraryMetadata = z.infer<typeof ItineraryMetadataSchema>

/**
 * Forms
 */

export const StringFormSchema = z.object({
  value: z.string(),
})
export type StringFormState = z.infer<typeof StringFormSchema>
